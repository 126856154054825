
.newUser {
    margin: 50px 450px
  }
  
  .newUserForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    
  }
  
  .newUserItem {
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
  }
  
  .newUserItem > label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(151, 150, 150);
  }
  
  .newUserItem > input {
    height: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .newUserGender > input {
    margin-top: 15px;
  }
  
  .newUserGender>label{
      margin: 10px;
      font-size: 18px;
      color: #555;
  }

  .newUserButton{
      width: 200px;
      border: none;
      background-color: #0A69AF;
      color: white;
      padding: 7px 10px;
      font-weight: 600;
      border-radius: 10px;
      margin-top: 30px;
      cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .newUser {
      margin: 50px 60px
    }
  }