

  .Menu
  {
    margin: 0 10px;
    margin-top:10px
  }
  .MenuWrapper 
  {
    list-style:none;
    padding: 0 5px;
    color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    float:left
  }
  .MenuItem
  {
    margin:5px 5px;
    padding: 5px;
    background-color: #3d91cc;
    border:1px solid #3d91cc;
    font-size: 18px;
    font-weight: 450;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-right: 40px;
    color:white;

  }
.Sidebar
{
  width:12%;
  height:20%;
}



  @media only screen and (max-width: 600px) {
    .MenuWrapper{
      flex-direction:row;
    }
    .MenuItem{
      margin-right:5px
    }
    .Sidebar{
      width:12%

    }
  }