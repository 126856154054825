.topbar
 {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .topbarWrapper 
  {
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo 
  {
    font-weight: bold;
    font-size: 30px;
    color: #0A69AF;
    cursor: pointer;
  }
  
  .topRight
  {
      padding-top: 5px;
      display: flex;
      align-items: center;
  }
  
  .topbarIconContainer 
  {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
  }
  
  .topIconBadge
  {
      width: 15px;
      height: 15px;
      position: absolute;
      top: -5px;
      right: 0px;
      background-color: #0A69AF;
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
  }
  
  .topAvatar
  {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
  }

  .topLeft
  {
    display:flex;
    justify-element:space-between;
    align-items:center;
  }

  .Menu
  {
    margin: 0 10px;
    margin-top:10px
  }
  .MenuWrapper 
  {
    list-style:none;
    padding: 0 5px;
    color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    float:left
  }
  .MenuItem
  {
    margin:5px 5px;
    padding: 5px;
    background-color: #3d91cc;
    border:1px solid #3d91cc;
    font-size: 18px;
    font-weight: 450;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-right: 40px;
    color:white;

  }
.Sidebar
{
  width:12%;
  height:20%;
}



  @media only screen and (max-width: 600px) {
    .MenuWrapper{
      flex-direction:row;
    }
    .MenuItem{
      margin-right:5px
    }
    .Sidebar{
      width:12%

    }
  }