.container {
  display: flex;
  padding: 50px 20px;
  text-align:center
}
.super {
  width: 30%;
}
.bitch {
  width: 100%;
}
.Link {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .bitch {
    width: 100%;
  }
}
