.betslipsList
{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center
}

.betslipsHeader
{
    width:100%;
    margin:20px 20px;
    display: flex;
    justify-content: space-between;
}
.BetslipsFiltre > span 
{
    color:rgb(80, 80, 80);
    margin-right: 7px;
}
.betslipsFailedButton
{
    border: 1px solid #d95087;
    border-radius: 10px;
    padding: 5px 10px;
    color: #d95087;
    background-color: white;
    cursor: pointer;
    margin-right: 20px;
}







.betslipListDelete{
    color: red;
    cursor: pointer;
}
