.userlist
{
    flex: 4;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #db6511;
    font-weight: 600;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.betslipSob{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #f56565;
    font-weight: 600;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.betslipGain{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.betslipGain.won{

    background-color: #3bb077 !important;
}

.betslipGain.lost{

    background-color: #e73232 !important;
}

.betslipGain.open{

    background-color: #dda424 !important;
}

.betslipGain.return{

    background-color: grey !important;
}



.userListDelete{
    color: red;
    cursor: pointer;
}
.userHeader
{
    display: flex;
    justify-content: space-between;
    margin: 25px 20px;
}
.Filters
{
    display: flex;
    list-style:none;
    justify-content: space-between;
}
.filterButton
{
    margin: 0 5px;
    background-color: white;
    color: #0A69AF;
    border: 1px solid #0A69AF;
    border-radius: 5px;
    width: 60px;
    height:25px;
    cursor: pointer;
}
.filterButton:hover
{
    background-color:#0A69AF ;
    color: white;
}
.search
{
    display: flex;
    justify-content: space-between;
}
.searchBox
{
    margin-left: 10px;
}
.Link {
    text-decoration: none;
    color: inherit;
  }