.widgetlg
{
    flex: 2;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;
}
.widgetLgTitle
{
    font-size: 22px;
    font-weight: 600;
}
.widgetLgTable
{
    width: 100%;
    border-spacing: 20px;

}
.widgetLgTh
{
    text-align: left;
}
.widgetLgId
{
    font-weight: 600;
}
.widgetLgDate,.widgetLgAmount
{
    font-weight: 300;
}

.widgetLgButton {
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
  }
  
  .widgetLgButton.Deposit
  {
      background-color: #e5faf2;
      color: #3bb077;
  }
  .widgetLgButton.Retrait
  {
      background-color: #fff0f1;
      color: #d95087;
  }